(function() {
	const parser = new DOMParser();

	document.querySelectorAll("iframe[name]").forEach(iframe => {
		iframe.addEventListener("load", e => {
			let url = "blank";

			try {
				url = iframe.contentWindow.location.pathname + iframe.contentWindow.location.search;
			}
			catch(ex) {}

			let name = iframe.name;
			let targetLink = document.querySelector(`a[target='${name}'][href^='${url}']`);
			if(url === "blank") {
				iframe.style.display = "none";
				if(iframe.dataset.update) {
					let updateEl = document.querySelector(iframe.dataset.update);
					fetch(location.href, {
						credentials: "same-origin",
						headers: {
							"Accept": "text/html",
						}
					})
						.then(response => response.text())
						.then(html => {
							let newDocument = parser.parseFromString(html, "text/html");
							updateEl.replaceWith(newDocument.querySelector(iframe.dataset.update));
						});
				}
			}
			else {
				let box = targetLink.getBoundingClientRect();
				iframe.style.display = "block";
				iframe.style.top = box.y - 100 + "px";
				iframe.style.left = box.x - 80 + "px";
			}
		});
	});

})();
