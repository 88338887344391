(function(){
	const lazyPagedList = document.querySelectorAll("[data-lazy-table=paged]");
	const parser = new DOMParser();
	const searchParams = new URLSearchParams(location.search);
	let page = searchParams.get("page") ?? 0;

	if(lazyPagedList.length > 0) {
		nextPage();
	}

	const lazyUpdateRowList = document.querySelectorAll("[data-lazy-table=updateRow]");
	lazyUpdateRowList.forEach(el => addUpdateRowEvent(el));

	document.querySelectorAll("[data-lazy-table=paged] tr").forEach(hookupRowEvents);

	const observer = new MutationObserver(mutations => {
		mutations.forEach(mutation => {
			mutation.addedNodes.forEach(child => {
				child.querySelectorAll("[data-lazy-table=updateRow]").forEach(el => addUpdateRowEvent(el));
			});
		});
	});
	observer.observe(
		document.getElementById("flagScope").parentElement, {
			subtree: false,
			childList: true
		}
	);

	function nextPage() {
		page++;

		let newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set("page", page);

		let newUrl = location.pathname + "?" + newSearchParams;

		fetch(newUrl, {
			credentials: "same-origin",
			headers: {
				"Accept": "text/html",
				"X-Lazy-Page": page
			}
		})
			.then(response => response.text())
			.then(html => {
				let newFragmentList = [];
				let newDocument = parser.parseFromString(html, "text/html");
				let newLazyUpdateRowList = newDocument.querySelectorAll("[data-lazy-table=updateRow]");
				newLazyUpdateRowList.forEach(el => addUpdateRowEvent(el));

				const newLazyElementList = newDocument.querySelectorAll("[data-lazy-table=paged]");
				newLazyElementList.forEach(newLazyElement => {
					if(!newLazyElement.firstElementChild) {
						return;
					}

					newLazyElement.querySelectorAll("tr").forEach(hookupRowEvents);

					let fragment = document.createDocumentFragment();
					while(newLazyElement.firstElementChild) {
						fragment.appendChild(newLazyElement.firstElementChild);
					}

					newFragmentList.push(fragment);
				});

				for(let i = 0, len = lazyPagedList.length; i < len; i++) {
					let lazyElement = lazyPagedList[i];
					let newLazyFrag = newFragmentList[i];
					if(!newLazyFrag) {
						continue;
					}

					let triggerElement = newLazyFrag.firstElementChild;
					let appended = lazyElement.appendChild(newLazyFrag);

// TODO: Only trigger the next page if the trigger element is visible.
// Do this with the Intersection Observer API.
// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

					if(triggerElement) {
						nextPage();
					}
				}
			});
	}

	function addUpdateRowEvent(form) {
		form.addEventListener("submit", e => {
			e.preventDefault();

			let uuid = form["uuid"].value;
			let formData = new FormData(form);
			formData.append("do", form.querySelector("button[name=do]").value);

			fetch(location, {
				method: "POST",
				credentials: "same-origin",
				headers: {
					"Accept": "text/html",
				},
				body: formData,
			})
				.then(response => response.text())
				.then(html => {
					let newDocument = parser.parseFromString(html, "text/html");
					let newForm = newDocument.querySelector(`[data-uuid='${uuid}'] form`);
					addUpdateRowEvent(newForm);
					form.closest("tr").replaceWith(newForm.closest("tr"));
				});
		});
	}

	function hookupRowEvents(tr) {
		tr.addEventListener("click", function(e) {
			if(e.target.tagName !== "TD") {
				return true;
			}

			location.href = "./edit/?uuid=" + e.target.parentNode.dataset["uuid"];
		});
	}

	nextPage();
})();
